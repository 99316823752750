import moment from "moment";
import momentTZ from "moment-timezone";

// momentjs timezone config
const tzLocal = process.env.TZ || "Pacific/Auckland"; // timezone NZ
// momentTZ.tz.setDefault(tzLocal);

export const now = momentTZ();

export const fromUTCtoLocal = (time: Date | string) => {
  const date = moment(time).tz(tzLocal);
  if (date.utcOffset() > 720) {
    date.subtract(1, "hour");
  }
  return date;
};

export const getUtcOffsetHoursToLocal = () => {
  return momentTZ.tz(new Date(), tzLocal).utcOffset();
};

export const getCurrentRoutesOnlyToDate = () => {
  return new Date(new Date().setMinutes(new Date().getMinutes() + getUtcOffsetHoursToLocal())).toJSON();
};

export const getCurrentRoutesOnlyFromDate = () => {
  return new Date(new Date().setMonth(new Date().getMonth() - 3)).toJSON();
};

export const formatDate = (time: Date | string, formatter: string) => {
  return momentTZ.tz(time, tzLocal).format(formatter);
};

export const convertFormatedDateToWholeDate = (dateToConvert: string, currentFormat: string) => {
  return momentTZ.tz(dateToConvert, currentFormat, tzLocal).utc();
};

export const getValueOfFromDateStringAndTimeString = (timeString: string, dateString: string) => {
  let hoursToAdd = 0;
  const [hours, minutes] = timeString.split(":").map((el: string) => parseInt(el));
  if (timeString.includes("pm")) {
    hoursToAdd += timeString.includes("12") ? 0 : 12;
  }
  const date = convertFormatedDateToWholeDate(dateString, "DD/MM/YY");
  date.add(hours + hoursToAdd, "hours");
  date.add(minutes, "minutes");
  return date.valueOf();
};

export const convertTimeFromUTCArray = (timeToConvertArray: any) => {
  if (!timeToConvertArray.legnth) return timeToConvertArray;
  let i = 0;
  for (const timeToConvert of timeToConvertArray) {
    timeToConvertArray[i] = convertTimeFromUTC(timeToConvert);
    i++;
  }

  return timeToConvertArray;
};

export const convertTimeFromUTCItems = (timeToConvert: any) => {
  let i = 0;
  for (const items of timeToConvert.items) {
    timeToConvert.items[i] = convertTimeFromUTCISO(items);
    i++;
  }

  return timeToConvert;
};

export const convertTimeFromUTCISO = (timeToConvert: any) => {
  for (const d in timeToConvert) {
    if ((d.includes("Time") && !d.includes("travelTimeEst")) || d.includes("startActual")) {
      const isoDate = new Date(timeToConvert[d]);
      const newDate = new Date();
      newDate.setDate(isoDate.getDate());
      newDate.setHours(isoDate.getUTCHours());
      newDate.setMinutes(isoDate.getUTCMinutes());
      newDate.setSeconds(isoDate.getUTCSeconds());
      timeToConvert[d] = momentTZ.tz(newDate, tzLocal).toISOString();
    }
  }

  return timeToConvert;
};

export const convertTimeFromUTC = (timeToConvert: any) => {
  for (const d in timeToConvert) {
    if (d.includes("Time")) {
      const [hours, minutes, seconds] = timeToConvert[d].split(":");
      const newDate = new Date();
      newDate.setHours(+hours);
      newDate.setMinutes(+minutes);
      newDate.setSeconds(+seconds);
      const date = moment(newDate);
      if (date.utcOffset() > 720) {
        date.add(1, "hour");
      }
      timeToConvert[d] = date.utc().format("HH:mm:ss");
    }
  }
  return timeToConvert;
};

export const convertTimeToUTC = (timeToConvert: any) => {
  for (const d in timeToConvert) {
    if (d.includes("Time")) {
      const [hours, minutes] = timeToConvert[d].split(":");
      const newDate = new Date();
      newDate.setHours(+hours);
      newDate.setMinutes(+minutes);
      newDate.setSeconds(0);
      const date = moment(newDate).tz(tzLocal);
      if (date.utcOffset() > 720) {
        date.add(1, "hour");
      }
      timeToConvert[d] = date.utc().format("HH:mm:ss");
    }
  }
  return timeToConvert;
};
