import { call, takeEvery, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import routeApi from "@/api/route";
import { toError } from "@/shared/utils/toError";
import { historyActions } from "./historySlice";
import { OrdersQueryParams, RouteResponse, RoutesQueryParams } from "@/shared/interfaces";
import orderApi from "@/api/order";
import { toast } from "react-toastify";

function* handleRequestRoutes(action: PayloadAction<RoutesQueryParams>) {
  action.payload.isHistory = true;
  const queryParams = action.payload;
  try {
    const { data }: { data: RouteResponse } = yield call(routeApi.getRoutes, queryParams);
    yield put(historyActions.getRoutesSuccess(data));
  } catch (error) {
    const err = toError(error);
    yield put(historyActions.requestFailed());
    toast.error(err.message);
  }
}

function* handleRequestOrders(action: PayloadAction<OrdersQueryParams>) {
  const queryParams = action.payload;
  try {
    const { data } = yield call(orderApi.getOrders, queryParams);
    yield put(historyActions.getOrdersSuccess(data));
  } catch (error) {
    const err = toError(error);
    yield put(historyActions.requestFailed());
    toast.error(err.message);
  }
}

function* handleRemoveRouteFromPOD(action: PayloadAction<{ id: number; params: RoutesQueryParams }>) {
  const { id, params } = action.payload;
  try {
    yield call(routeApi.removeRouteFromTrackPOD, id);
    const { data } = yield call(routeApi.getRoutes, params);
    yield put(historyActions.deleteRouteSuccess(data));
  } catch (error: any) {
    yield put(historyActions.requestFailed());
    toast.error(error.response.data.message);
  }
}

function* handleDeleteRoute(action: PayloadAction<{ id: number; params: RoutesQueryParams }>) {
  const { id, params } = action.payload;
  try {
    yield call(routeApi.deleteRoute, id);
    const { data } = yield call(routeApi.getRoutes, params);
    yield put(historyActions.deleteRouteSuccess(data));
    toast.success("Deleted Successfully!!");
  } catch (error) {
    const err = toError(error);
    yield put(historyActions.requestFailed());
    toast.error(err.message);
  }
}

export default function* watchHistorySaga() {
  yield takeEvery(historyActions.getRoutes.type, handleRequestRoutes);
  yield takeEvery(historyActions.getOrders.type, handleRequestOrders);
  yield takeLatest(historyActions.deleteRoute.type, handleDeleteRoute);
  yield takeLatest(historyActions.removeRouteFromPOD.type, handleRemoveRouteFromPOD);
}
